import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Related from "../sections/landing/popular.js";
import Find from '../sections/nose/nosefind.js';
import NoseContent from '../sections/nose/nosecontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Gallery from "../sections/nose/nosegallery.js";
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import Glance from "../sections/tat/tatglance.js";
import Content1 from "../sections/tat/tatcontent1.js";
import Testimonial from "../sections/reviews/nosereviews.js";
import Liquid from "../sections/nose/nosevs.js";
import Nosefaq from "../sections/tat/tatfaq.js"
import Saveface from "../sections/landing/saveface";
import Steps from '../sections/nose/nosesteps.js';
import NoseBlog from "../sections/nose/noseblog.js";
import Brand from "../sections/homepage/Brands.js";
import Video from "../sections/nose/nosevideo.js";
import ReviewsD from "../sections/nose/nosedesktopreviews.js";
import Award from "../sections/index/award.js";
import NoseCount from "../sections/nose/nosecounters.js";
import MyImage from "../assets/image/jpeg/tattoo-removal.jpg";

const titleText = "Expert Laser Tattoo Removal";
const textContent = "Medical grade safe Tattoo Removal";

const TattooPage = () => ( 
  <>
 <Head title="Tatto Removal in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <TwoColumnLayout title={titleText} text={textContent} imageData={null} image={MyImage} />
      <Glance />
      <Content1 />
     
      
   
  <ReviewsD />
      <Testimonial />
   
 
    
    
      <Nosefaq />
      <Related />
      <Find />
      <NoseBlog />
      <Brand />
      <Saveface />
      
 
     </PageWrapper>
 
  </>
)
export default TattooPage
